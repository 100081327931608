<template>
  <section class="content">
    <table class="table table-hover" ref="tblkelas">
      <thead>
        <tr>
          <th>PROGRAM</th>
          <th>DESKRIPSI</th>
          <th>JUMLAH UJIAN</th>
          <th>STATUS</th>
          <th>TINDAKAN</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Nama Program</label>
                  <input
                    id="program"
                    class="form-control"
                    v-model="form.name"
                    type="text"
                    required="required"
                    name="program"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Deskripsi</label>
                  <input
                    id="deskripsi"
                    class="form-control"
                    v-model="form.deskripsi"
                    type="text"
                    name="deskripsi"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label"></label>
                  <input
                    id="active"
                    v-model="form.active"
                    type="checkbox"
                    name="active"
                  />
                  <label for="active">Active</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "",
      form: {
        active: "",
        name: "",
        deskripsi: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }

      if (e.target.closest("button")) {
          if (e.target.dataset.action == "view") {
              this.$router.push("/ujian-kelas/detail/" + e.target.dataset.id);
          }
      }
    },
    submitForm: function (ev) {},
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
      title: "",
      roles: ["read"],
      ajax: "/akademik/ujian/per_program",
      selectedRowClass: "",
      columns: [
        { data: "name" },
        { data: "deskripsi" },
        { data: "jumlah_ujian" },
        { data: "active" },
        { data: "action",
          sortable: false,
        render: function (data, type, row, meta) {
            return `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="`+row.id+`"><i class="fas fa-eye"></i> Detail</button></div>`;
        }, },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        if (data.active == "t")
          $("td:eq(3)", row).html(
            '<span class="badge badge-success">Active</span>'
          );
        else if (data.active == "f")
          $("td:eq(3)", row).html(
            '<span class="badge badge-warning">Not Active</span>'
          );
      },
    });
  },
};
</script>
